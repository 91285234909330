import "./styles.css";

import PartySocket from "partysocket";
import {pack, unpack} from "msgpackr";

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
    output.appendChild(document.createTextNode(text));
    output.appendChild(document.createElement("br"));
}

// Canvas drawing logic
function animateCanvas(canvasId: string) {
    const canvas = document.getElementById(canvasId);
    // @ts-ignore
    const ctx = canvas.getContext('2d');
    // @ts-ignore
    let x = canvas.width / 2;
    // @ts-ignore
    let y = canvas.height / 2;
    let dx = 2;
    let dy = -2;
    const ballRadius = 40;
    let color = 'red';

    function drawBall() {
        ctx.beginPath();
        ctx.arc(x, y, ballRadius, 0, Math.PI * 2);
        ctx.fillStyle = color;
        ctx.fill();
        ctx.closePath();
    }

    function randomColor() {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    function animate() {
        ctx.fillStyle = "#EEEEEE";
        // @ts-ignore
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        drawBall();
        // @ts-ignore
        if (x + dx > canvas.width - ballRadius || x + dx < ballRadius) {
            dx = -dx;
            color = randomColor();
        }
        // @ts-ignore
        if (y + dy > canvas.height - ballRadius || y + dy < ballRadius) {
            dy = -dy;
            color = randomColor();
        }
        x += dx;
        y += dy;
        requestAnimationFrame(animate);
    }

    animate();
}

function startStreaming() {
    const drawingCanvas = document.getElementById('drawingCanvas');
    const sendingCanvas = document.getElementById('sendingCanvas');
    // @ts-ignore
    const sendingCtx = sendingCanvas.getContext('2d');

    setInterval(() => {
        // Copy the contents of drawingCanvas to sendingCanvas
        // @ts-ignore
        sendingCtx.clearRect(0, 0, sendingCanvas.width, sendingCanvas.height);
        sendingCtx.drawImage(drawingCanvas, 0, 0, 150, 150);
        // @ts-ignore
        conn.send(pack(sendingCanvas.toDataURL()))
    }, 1000 / 10);
}


// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
    host: PARTYKIT_HOST,
    room: "cctv-test",
});

async function loadImageAndApplyEffect(dataUrl) {
    const canvas: HTMLCanvasElement = document.getElementById('receivingCanvas');
    const gl = canvas.getContext('webgl');

    if (!gl) {
        console.error('Unable to initialize WebGL. Your browser may not support it.');
        return;
    }

    // Shader sources
    const vsSource = `
        attribute vec4 aVertexPosition;
        attribute vec2 aTextureCoord;
        varying highp vec2 vTextureCoord;

        void main(void) {
            gl_Position = aVertexPosition;
            vTextureCoord = aTextureCoord;
        }
    `;

    const fsSource = `
        precision mediump float;
        varying highp vec2 vTextureCoord;
        uniform sampler2D uSampler;
        uniform vec2 iResolution;
        float warp = 0.75;
        float scan = 0.8;

        void main(void) {
            vec2 uv = vTextureCoord;
            vec2 dc = abs(0.5 - uv);
            dc *= dc;

            uv.x -= 0.5; uv.x *= 1.0 + (dc.y * (0.3 * warp)); uv.x += 0.5;
            uv.y -= 0.5; uv.y *= 1.0 + (dc.x * (0.4 * warp)); uv.y += 0.5;

            if (uv.y > 1.0 || uv.x < 0.0 || uv.x > 1.0 || uv.y < 0.0) {
                gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
            } else {
                float apply = abs(sin(vTextureCoord.y * iResolution.y) * 0.5 * scan);
                vec4 texColor = texture2D(uSampler, uv);
                gl_FragColor = vec4(mix(texColor.rgb, vec3(0.0), apply), 1.0);
            }
        }
    `;

    // Initialize shaders and program
    const shaderProgram = initShaderProgram(gl, vsSource, fsSource);

    const programInfo = {
        program: shaderProgram,
        attribLocations: {
            vertexPosition: gl.getAttribLocation(shaderProgram, 'aVertexPosition'),
            textureCoord: gl.getAttribLocation(shaderProgram, 'aTextureCoord'),
        },
        uniformLocations: {
            uSampler: gl.getUniformLocation(shaderProgram, 'uSampler'),
            iResolution: gl.getUniformLocation(shaderProgram, 'iResolution'),
        },
    };

    // Set up positions and texture coordinates buffers
    const buffers = initBuffers(gl);

    // Load the image
    const texture = loadTexture(gl, dataUrl);

    function render() {
        if (texture.loaded) {
            drawScene(gl, programInfo, buffers, texture);
        } else {
            requestAnimationFrame(render);
        }
    }

    render();
}

function initShaderProgram(gl, vsSource, fsSource) {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        console.error('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
        return null;
    }

    return shaderProgram;
}

function loadShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

function initBuffers(gl) {
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = [
        -1.0,  1.0,
        1.0,  1.0,
        -1.0, -1.0,
        1.0, -1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

    const textureCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
    const textureCoordinates = [
        0.0,  0.0,
        1.0,  0.0,
        0.0, 1.0,
        1.0, 1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), gl.STATIC_DRAW);

    return {
        position: positionBuffer,
        textureCoord: textureCoordBuffer,
    };
}

function loadTexture(gl, url) {
    const texture = gl.createTexture();
    texture.loaded = false; // Custom flag to check if texture is loaded
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Initialize texture with a single pixel first
    const level = 0;
    const internalFormat = gl.RGBA;
    const width = 1;
    const height = 1;
    const border = 0;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    const pixel = new Uint8Array([0, 0, 255, 255]);
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, srcFormat, srcType, pixel);

    const image = new Image();
    image.onload = function() {
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);

        if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
            gl.generateMipmap(gl.TEXTURE_2D);
        } else {
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        }

        texture.loaded = true;
    };
    image.src = url;

    return texture;
}

function isPowerOf2(value) {
    return (value & (value - 1)) == 0;
}

function drawScene(gl, programInfo, buffers, texture) {
    gl.clearColor(0.0, 0.0, 0.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.useProgram(programInfo.program);

    // Set positions
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
    gl.vertexAttribPointer(programInfo.attribLocations.vertexPosition, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);

    // Set texture coords
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
    gl.vertexAttribPointer(programInfo.attribLocations.textureCoord, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(programInfo.attribLocations.textureCoord);

    // Set the texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.uniform1i(programInfo.uniformLocations.uSampler, 0);

    // Set the resolution
    gl.uniform2f(programInfo.uniformLocations.iResolution, gl.canvas.width, gl.canvas.height);

    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
}

// You can even start sending messages before the connection is open!
conn.addEventListener("message",  async (event) => {
    if (event.data instanceof Blob) {
        const data = unpack(await event.data.arrayBuffer())
        await loadImageAndApplyEffect(data)
    } else {
        const data = JSON.parse(event.data)
        if (data.type === "assign") {
            if (data.role === "host") {
                add("You are the host!")
                animateCanvas('drawingCanvas')
                startStreaming()
            } else {
                add("You are a guest.");
            }
        }
    }
})
